import axios from 'src/utils/axios';
import {PLATFORM_API_URL} from 'src/config';


class AppleAuthService {

  // Fetches the authentication token for apple maps API.
  generateAppleMapsToken = () => new Promise((resolve, reject) => {
    axios.get('https://bedws.impactsvc.com/auth/apple/token')
      .then((response) => {
        const {token} = response.data;
        if (token) {
          resolve(token);
        } else {
          resolve('');
        }
        //resolve('eyJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiIsImtpZCI6IllSUzVYVlQ0UFEifQ.eyJleHAiOjE2MTQ0NDU5NjgsImlhdCI6MTYwNjY2OTk2OCwiaXNzIjoiM0NDOUhONjJaOSIsIm9yaWdpbiI6Imh0dHA6Ly9sb2NhbGhvc3Q6MzAwMCJ9.at0ML7iGdPK7tiGNGUJHdY3tDYEaVfrrrS2d-feKvCfgXDXm1ehnUMu2_Nhlj4Tp2lqHT8OFnbH-86R37LtNHQ');
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  });

}

const appleAuthService = new AppleAuthService();
export default appleAuthService;
