import axios from 'src/utils/axios';
import securityStorage from '../storage/security';
import { PLATFORM_API_URL, PLATFORM_TYPE } from 'src/config';
import { UNK_ERROR } from 'src/constants';

// Provides authentication services for the network.
class AuthService {

  // Authenticates a member account with the network using a email and password.
  // Status: 200, 401
  authWithEmailAndPassword = (email, pass) => new Promise((resolve, reject) => {
    axios.get('https://bedws.impactsvc.com/auth/creds/' + email + '/' + pass)
      .then((response) => {
        const account = response.data;
        if (account && account.length > 0) {
          securityStorage.setAuthEmail(email);
          securityStorage.setAuthToken(null, '');
          resolve(account[0]);
        } else {
          reject(UNK_ERROR);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response && error.response.data) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  });

  // Authenticates a stored Json Web Token with the network.
  // The token should already be loaded into the axios authentication header.
  //
  // Status: 200, 401
  authWithJsonWebToken = () => new Promise( (resolve, reject) => {
    axios.get(PLATFORM_API_URL + '/auth/token/jwt')
      .then ((response) => {
        const {token, account, instance} = response.data;
        if (token) {
          resolve({account, instance});
        } else {
          reject(UNK_ERROR);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  });

  // Verifies a MultiFactor Authentication token with the network.
  //
  // Status: 200, 401
  authWithMFAToken = (cid, token) => new Promise((resolve, reject) => {
    axios.post(PLATFORM_API_URL + '/auth/token/mfa', {cid, token})
      .then((response) => {
        if (response.data.token) {
          securityStorage.setAuthToken(cid, response.data.token);
          resolve(response.data.instance);
        } else {
          reject(UNK_ERROR);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  });

  // Logs the currently authenticated member account off the network.
  // Status: 204, 404
  //
  // Returns: null
  logOffNetwork = () => new Promise((resolve, reject) => {
    axios.delete(PLATFORM_API_URL + '/auth/logoff')
      .then ((response) => {
        if (response.status === 204) {
          resolve();
        } else {
          reject(UNK_ERROR);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      })
      .finally(() => {
        securityStorage.revokeAuthTokens();
      });
  });

  // Logs the currently authenticated member account out of the current company instance.
  // This is accomplished by removing the auth token and resetting
  // the axios authorization header to the generic auth token.
  //
  // If a null id is passed in, nothing will happen.
  logOffInstance = () => {
    const id = securityStorage.getAuthInstanceId()
    if (id) {
      securityStorage.setAuthToken(id, null);
    }
  };

}

const authService = new AuthService();
export default authService;
